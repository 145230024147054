import { Button } from "@chakra-ui/button";
import { Heading, Stack } from "@chakra-ui/layout";
import React from "react";
import { signIn } from "next-auth/client";
import { Page } from "components/page";
import { CustomHead } from "components/custom-head";
import { TweetContext } from "../context/tweetContext";

export default function Custom404() {

  const tweetContext: any = React.useContext(TweetContext);

  const baseUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://app.tweethunter.io";

  React.useEffect(() => {
    tweetContext.setIsOpen(false);
  }, []);

  return (
    <Page>
      <CustomHead noIndex={true} />
      <Stack display="flex" flexDirection="column" alignItems="center" maxW={400} p={50} spacing={10} margin="auto" mt="5vh">
        <Heading fontSize="2xl" textAlign="center">
          Oups, there is nothing here
        </Heading>
        <Button
          maxW={200}
          variant={"secondary"}
          onClick={() =>
            signIn("twitter", {
              callbackUrl: baseUrl + "/search",
            })
          }
        >
          Go back
        </Button>
      </Stack>
    </Page>
  );
};
